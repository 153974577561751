import timg1 from "../images/team/img-5.jpg";
import timg2 from "../images/team/img-6.jpg";
import timg3 from "../images/team/img-7.jpg";
import timg4 from "../images/team/img-8.jpg";

const Teams = [
  {
    id: "1",
    tImg: timg1,
    name: "Jenny Wilson",
    slug: "Jenny-Wilson",
    title: "Wedding Planner",
  },
  {
    id: "2",
    tImg: timg2,
    name: "Robert Fox",
    slug: "Robert-Fox",
    title: "Dress Designer",
  },
  {
    id: "3",
    tImg: timg3,
    name: "Annette Black",
    slug: "Annette-Black",
    title: "Makeup Artist",
  },
  {
    id: "4",
    tImg: timg4,
    name: "Jenefer Abram",
    slug: "Jenefer-Abram",
    title: "CEO",
  },
];

export default Teams;
