import simg1 from "../images/service/img-1.jpg";
import simg2 from "../images/service/img-2.jpg";
import simg3 from "../images/service/img-3.jpg";
import simg4 from "../images/service/img-4.jpg";

import sVector1 from "../images/service/img-1.svg";
import sVector2 from "../images/service/img-2.svg";
import sVector3 from "../images/service/img-3.svg";
import sVector4 from "../images/service/img-4.svg";

import sinnermg1 from "../images/service-single/2.jpg";
import sinnermg2 from "../images/service-single/3.jpg";

import singleImg1 from "../images/service-single/img-1.jpg";
import singleImg2 from "../images/service-single/img-2.jpg";
import singleImg3 from "../images/service-single/img-3.jpg";
import singleImg4 from "../images/service-single/img-4.jpg";
import singleImg5 from "../images/service-single/img-5.jpg";

const Services = [
  {
    id: "1",
    title: "Wedding Dress",
    slug: "Wedding-Dress",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: simg1,
    vector: sVector1,
    ssImg: singleImg1,
    sinnerImg1: sinnermg1,
    sinnerImg2: sinnermg2,
  },
  {
    id: "2",
    title: "Accessories",
    slug: "Accessories",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: simg2,
    vector: sVector2,
    ssImg: singleImg2,
    sinnerImg1: sinnermg1,
    sinnerImg2: sinnermg2,
  },
  {
    id: "3",
    title: "Flower Bouquet",
    slug: "Flower-Bouquet",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: simg3,
    vector: sVector3,
    ssImg: singleImg3,
    sinnerImg1: sinnermg1,
    sinnerImg2: sinnermg2,
  },
  {
    id: "4",
    title: "Wedding Cake",
    slug: "Wedding-Cake",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    simg1: simg4,
    vector: sVector4,
    ssImg: singleImg4,
    sinnerImg1: sinnermg1,
    sinnerImg2: sinnermg2,
  },
  {
    id: "5",
    title: "Wedding Registry",
    slug: "Wedding-Registry",
    description:
      "Quis ipsome suspendisse ultrices gravid. Risus commod viverra maescums.",
    ssImg: singleImg5,
    sinnerImg1: sinnermg1,
    sinnerImg2: sinnermg2,
  },
];
export default Services;
