import React from "react";
import pImg3 from "../../images/pricing/shape-3.svg";
import pImg4 from "../../images/pricing/shape-4.svg";
import SectionTitle from "../SectionTitle";

const roomBlock =
  "If you would like to book a room here, please call the hotel directly at (954) 753-9000 and state that you would like to make a group reservation for the 'Persad Wedding' and you will receive a discount.";
const Hotels = [
  {
    pakage: "Fort Lauderdale West",
    price: "Renaissance ",
    l1: "1230 S Pine Island Rd, Plantation, FL 33324",
    l2: "Near Location of Sunday's events at Jacaranda Country Club",
    link: "https://book.passkey.com/gt/219582360?gtid=33599a1b09031c0f30ec32cf07656e53",
  },
  {
    pakage: "Coral Springs",
    price: "La Quinta ",
    l1: "3701 N University Dr, Coral Springs, FL 33065",
    l2: "Location of the Mehndi on Saturday",
    link: "https://www.wyndhamhotels.com/laquinta/coral-springs-florida/la-quinta-inn-and-suites-coral-springs-university-dr/overview?checkInDate=01/12/2024&checkOutDate=01/14/2024&groupCode=PER11324",
  },
];

const HotelsSection = (props) => {
  return (
    <section className={`wpo-pricing-section section-padding ${props.pClass}`}>
      <div className="container">
        <SectionTitle />
        <div className="wpo-pricing-wrap">
          <div className="row">
            {Hotels.map((hotel, pitem) => (
              <div className="col col-lg-6 col-md-6 col-12" key={pitem}>
                <div className="wpo-pricing-item">
                  <div className="wpo-pricing-item-inner">
                    <div className="wpo-pricing-top">
                      <div className="wpo-pricing-text">
                        <h2>{hotel.price}</h2>
                        <h4>{hotel.pakage}</h4>
                      </div>
                    </div>
                    <div className="wpo-pricing-bottom">
                      <div className="wpo-pricing-bottom-text">
                        <ul>
                          <li>{hotel.l1}</li>
                          <li>{hotel.l2}</li>
                          <li>{hotel.l4}</li>
                          <li>{hotel.l5}</li>
                        </ul>
                        {hotel.comingSoon ? (
                          <a href={hotel.link} target="_blank" rel="noopener noreferrer" className="theme-btn">
                            Visit Hotel Website
                          </a>
                        ) : (
                          <a href={hotel.link} target="_blank" rel="noopener noreferrer" className="theme-btn">
                            Choose Hotel
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="left-shape">
        <img src={pImg3} alt="" />
      </div>
      <div className="right-shape">
        <img src={pImg4} alt="" />
      </div>
    </section>
  );
};

export default HotelsSection;
