// images
import blogImg1 from "../images/blog/img-1.jpg";
import blogImg2 from "../images/blog/img-2.jpg";
import blogImg3 from "../images/blog/img-3.jpg";
import blogImg4 from "../images/blog/img-4.jpg";
import blogImg5 from "../images/blog/img-5.jpg";
import blogImg6 from "../images/blog/img-6.jpg";
import blogImg7 from "../images/blog/img-7.jpg";
import blogImg8 from "../images/blog/img-8.jpg";
import blogImg9 from "../images/blog/img-9.jpg";

import blogSingleImg1 from "../images/blog-details/img-1.jpg";
import blogSingleImg2 from "../images/blog-details/img-2.jpg";
import blogSingleImg3 from "../images/blog-details/img-3.jpg";
import blogSingleImg4 from "../images/blog-details/img-4.jpg";
import blogSingleImg5 from "../images/blog-details/img-5.jpg";
import blogSingleImg6 from "../images/blog-details/img-6.jpg";
import blogSingleImg7 from "../images/blog-details/img-7.jpg";
import blogSingleImg8 from "../images/blog-details/img-8.jpg";
import blogSingleImg9 from "../images/blog-details/img-9.jpg";

const blogs = [
  {
    id: "1",
    title: "Top 10 wedding bouquet arranging idea",
    slug: "Top-10-wedding",
    screens: blogImg1,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.",
    author: "Loura Sweety",
    create_at: "25 Sep 2023",
    blogSingleImg: blogSingleImg1,
    comment: "35",
    blClass: "format-standard-image",
    animation: "1200",
  },
  {
    id: "2",
    title: "You must need a great photographer.",
    slug: "photographer",
    screens: blogImg2,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.",
    author: "David Luis",
    create_at: "23 Sep 2023",
    blogSingleImg: blogSingleImg2,
    comment: "80",
    blClass: "format-standard-image",
    animation: "1400",
  },
  {
    id: "3",
    title: "Make sure your wedding gown is the best one.",
    slug: "wedding-gown",
    screens: blogImg3,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.",
    author: "Jenefer Willy",
    create_at: "21 Sep 2023",
    blogSingleImg: blogSingleImg3,
    comment: "95",
    blClass: "format-video",
    animation: "1600",
  },
  {
    id: "4",
    title: "Top 10 wedding bouquet arranging idea",
    slug: "bouquet-arranging",
    screens: blogImg4,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.",
    author: "Jenefer Willy",
    create_at: "21 Sep 2023",
    blogSingleImg: blogSingleImg4,
    comment: "95",
    blClass: "format-video",
    animation: "1200",
  },
  {
    id: "5",
    title: "You must need a great photographer.",
    slug: "need-a-great-photographer",
    screens: blogImg5,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.",
    author: "Loura Sweety",
    create_at: "25 Sep 2023",
    blogSingleImg: blogSingleImg5,
    comment: "35",
    blClass: "format-standard-image",
    animation: "1400",
  },
  {
    id: "6",
    title: "Make sure your wedding gown is the best one.",
    slug: "gown-is-the-best-one",
    screens: blogImg6,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.",
    author: "David Luis",
    create_at: "23 Sep 2023",
    blogSingleImg: blogSingleImg6,
    comment: "80",
    blClass: "format-standard-image",
    animation: "1600",
  },
  {
    id: "7",
    title: "Top 10 wedding bouquet arranging idea",
    title2: "أفضل 10 أفكار لترتيب باقة الزفاف",
    slug: "arranging-idea",
    screens: blogImg7,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.",
    author: "Jenefer Willy",
    create_at: "21 Sep 2023",
    blogSingleImg: blogSingleImg7,
    comment: "95",
    blClass: "format-video",
    animation: "1200",
  },
  {
    id: "8",
    title: "You must need a great photographer.",
    title2: "يجب أن تحتاج إلى مصور مثالي ورائع.",
    slug: "You-must-need-a-great-photographer",
    screens: blogImg8,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.",
    author: "Jenefer Willy",
    create_at: "21 Sep 2023",
    blogSingleImg: blogSingleImg8,
    comment: "95",
    blClass: "format-video",
    animation: "1400",
  },
  {
    id: "9",
    title: "Make sure your wedding gown is the best one.",
    title2: "تأكدي من أن فستان زفافك هو الأفضل.",
    slug: "Make-sure-your",
    screens: blogImg9,
    description:
      "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Amet autem beatae errodio.",
    author: "Loura Sweety",
    create_at: "25 Sep 2023",
    blogSingleImg: blogSingleImg9,
    comment: "35",
    blClass: "format-standard-image",
    animation: "1600",
  },
];
export default blogs;
