import React, { Fragment } from "react";
import Navbar from "../../components/Navbar/Navbar";
import PortfolioSection from "../../components/PortfolioSection";
import StorySection from "../../components/StorySection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";

const HomePage2 = () => {
  return (
    <Fragment>
      <Navbar />
      <StorySection />
      <PortfolioSection />
      <Footer footerClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage2;
