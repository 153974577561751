import React from "react";

const SectionTitle = (props) => {
  return (
    <div className={`wpo-section-title ${props.s2Class}`}>
      <span>The room block will be available until January 5th or until all allocated rooms are filled.</span>
    </div>
  );
};

export default SectionTitle;
