import React from "react";
import { Slide } from "react-awesome-reveal";
import ceremony from "../../images/laraibandnick/ceremony.jpg";
import mehndi from "../../images/laraibandnick/mendhi.jpg";
import reception from "../../images/laraibandnick/reception.jpg";
import SectionTitle2 from "../SectionTitle2";
import LocationMap from "./Modal";
import { Button } from "@mui/material";

// const { title, description, location, startTime, endTime } = event;
const Saturday = [
  {
    Simg: mehndi,
    title: "Saturday",
    li1: "The Mehndi",
    li2: "7:00pm",
    li3: "La Quinta Inn Banquet Hall",
    parking: "Complementary Self-parking is available at the venue.",
    li4: "3701 N University Dr, Coral Springs, FL 33065",
    location:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14310.271282861937!2d-80.2496437!3d26.2756864!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d905df185a0e7f%3A0x74b743232aee845a!2sLa%20Quinta%20Inn%20Banquet%20Hall!5e0!3m2!1sen!2sus!4v1700031080860!5m2!1sen!2sus",
    animation: "1200",
    description: "Guests are encouraged to wear colorful formal Pakistani or Indian attire or opt for Western wear.",
    event: {
      title: "The Mehndi",
      description: "Guests are encouraged to wear colorful formal Pakistani or Indian attire or opt for Western wear.",
      location: "3701 N University Dr, Coral Springs, FL 33065",
      startTime: new Date("January 13, 2024 19:00:00"),
      endTime: new Date("January 13, 2024 24:00:00"),
      download: "laraibandnick-mehndi.ics",
    },
  },
];
const Sunday = [
  {
    Simg: ceremony,
    title: "Sunday",
    li1: "The Ceremonies",
    li2: "4:30pm",
  },
  {
    Simg: ceremony,
    title: "Sunday",
    li1: "Cocktail Hour",
    li2: "6:00pm",
  },
  {
    Simg: reception,
    title: "Sunday",
    li1: "The Reception",
    li2: "7:00pm",
  },
];

const EventSectionS2 = (props) => {
  return (
    <section className="wpo-event-section section-padding" id="event">
      <div className="container">
        <SectionTitle2 subTitle={"Wedding Guide"} MainTitle={"January 13 - 14, 2024"} />
        <div className="wpo-event-wrap">
          <div className="row">
            <div className="col">
              <Slide direction="up" duration="300" triggerOnce="true">
                <div className="wpo-event-item">
                  <div className="wpo-event-text">
                    <div className="title">
                      <h2>Quick Links</h2>
                    </div>
                    <ul>
                      <li>
                        <a href="/gift" target="_blank" rel="noopener noreferrer" className="theme-btn-s3">
                          Registry
                        </a>
                      </li>
                      <li>
                        <a href="/hotels" rel="noopener noreferrer" className="theme-btn-s3">
                          Hotels
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </Slide>
            </div>
          </div>
          <div className="row">
            {Saturday.map((event, eitem) => (
              <div className="col" key={eitem}>
                <Slide direction="up" duration={event.animation} triggerOnce="true">
                  <div className="wpo-event-item">
                    {/* <div className="wpo-event-img">
                      <div className="wpo-event-img-inner">
                        <img src={event.Simg} alt="" />
                      </div>
                    </div> */}
                    <div className="wpo-event-text">
                      <div className="title">
                        <h2>{event.title}</h2>
                      </div>
                      <ul>
                        <li>
                          <strong>{event.li2}</strong> - {event.li1}
                        </li>
                        <li>
                          <strong>Venue Name:</strong> {event.li3}
                        </li>
                        <li>
                          <strong>Parking:</strong> {event.parking}
                        </li>
                        <li>
                          <strong>Address:</strong> {event.li4}
                        </li>
                        <li>
                          <strong>Attire:</strong> {event.description}
                        </li>
                        <li>
                          <a href="http://maps.apple.com/?q=26.275686, -80.249644" target="_blank" rel="noreferrer">
                            Open in Maps
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://ul.waze.com/ul?preview_venue_id=183369991.1833372228.464655&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Open in Waze
                          </a>
                        </li>
                        {/* <li>
                          <LocationMap location={event.location} />
                        </li> */}
                      </ul>
                    </div>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col">
              <Slide direction="up" duration="1400" triggerOnce="true">
                <div className="wpo-event-item">
                  {/* <div className="wpo-event-img">
                      <div className="wpo-event-img-inner">
                        <img src={event.Simg} alt="" />
                      </div>
                    </div> */}
                  <div className="wpo-event-text">
                    <div className="title">
                      <h2>Sunday</h2>
                    </div>
                    {Sunday.map((event, eitem) => (
                      <ul>
                        <li>
                          <strong>{event.li2}</strong> - {event.li1}
                        </li>
                      </ul>
                    ))}
                    <hr />
                    <ul>
                      <li>
                        <strong>Venue Name:</strong> Jacaranda Country Club
                      </li>
                      <li>
                        <strong>Parking:</strong> Complementary Valet or Self-parking is available at the venue.
                      </li>
                      <li>
                        <strong>Address:</strong> 9200 W Broward Blvd, Plantation, FL 33324
                      </li>
                      <li>
                        <strong>Attire:</strong> Guests have the option of wearing black-tie attire or Pakistani/Indian
                        formal wear.
                      </li>
                      <li>
                        <a href="http://maps.apple.com/?q=26.112788, -80.271869" target="_blank" rel="noreferrer">
                          Open in Maps
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://ul.waze.com/ul?preview_venue_id=183304453.1833241139.1249647&navigate=yes&utm_campaign=default&utm_source=waze_website&utm_medium=lm_share_location"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Open in Waze
                        </a>
                      </li>
                      {/* <li>
                        <LocationMap location="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.611349989007!2d-80.27752201189087!3d26.111602618174874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d907de9f73e7ef%3A0x1dbacb193f994b17!2sJacaranda%20Golf%20Club!5e0!3m2!1sen!2sus!4v1700017104764!5m2!1sen!2sus" />
                      </li> */}
                    </ul>
                  </div>
                </div>
              </Slide>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventSectionS2;
