import React from "react";
import { Zoom } from "react-awesome-reveal";
import firstdate from "../../images/laraibandnick/firstdate.png";
import proposal from "../../images/laraibandnick/proposal.png";
import wedding from "../../images/laraibandnick/wedding2.png";
import SectionTitle2 from "../SectionTitle2";

import shape1 from "../../images/story/shape.png";
import shape2 from "../../images/story/shape2.png";
import shape3 from "../../images/story/shape3.png";

import bshape1 from "../../images/story/flower-shape1.svg";
import bshape2 from "../../images/story/flower-shape2.svg";
import bshape3 from "../../images/story/flower-shape3.svg";
import bshape4 from "../../images/story/flower-shape4.svg";

const Storys = [
  {
    sImg: firstdate,
    shape: shape1,
    title: "First Date",
    date: "May 1, 2019",
    description:
      "Our story kicked off at Rocco's Tacos in Boca Raton, FL – laughter, great talks, and the vibe assured us there was something special between us. We both left that night knowing a second date was a must!",
  },
  {
    sImg: proposal,
    shape: shape2,
    title: "He proposed, She said yes",
    date: "December 31, 2021",
    description:
      "Nick proposed to Laraib on New Years Eve at sunset off the coast of Naples, FL. It was perfect!",
    order1: "order-lg-2 order-1",
    order2: "order-lg-1 order-2",
  },
  {
    sImg: wedding,
    shape: shape3,
    title: "Wedding Day",
    date: "January 14, 2024",
    description:
      "We're tying the knot at Jacaranda Country Club in Plantation, FL, and having you there will make our day extra special. We can't wait to share these moments with you!",
  },
];

const StorySection2 = (props) => {
  return (
    <section className="wpo-story-section section-padding" id="story">
      <div className="container">
        <SectionTitle2 subTitle={"Our Story"} MainTitle={"How it Happened"} />
        <div className="wpo-story-wrap">
          {Storys.map((story, st) => (
            <div className="wpo-story-item" key={st}>
              <div className="wpo-story-img-wrap">
                <Zoom direction="up" duration="1000" triggerOnce="true">
                  <div className="wpo-story-img">
                    <img src={story.sImg} alt="" />
                  </div>
                </Zoom>
                <div className="clip-shape">
                  <svg
                    viewBox="0 0 382 440"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M191 0L381.526 110V330L191 440L0.474411 330V110L191 0Z" />
                  </svg>
                </div>
                <div className="wpo-img-shape">
                  <img src={story.shape} alt="" />
                </div>
              </div>
              <div className="wpo-story-content">
                <div className="wpo-story-content-inner">
                  <span>{story.date}</span>
                  <h2>{story.title}</h2>
                  <p>{story.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flower-shape-1">
        <img src={bshape1} alt="" />
      </div>
      <div className="flower-shape-2">
        <img src={bshape2} alt="" />
      </div>
      <div className="flower-shape-3">
        <img src={bshape3} alt="" />
      </div>
      <div className="flower-shape-4">
        <img src={bshape4} alt="" />
      </div>
    </section>
  );
};

export default StorySection2;
