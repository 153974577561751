import React from "react";
import { JackInTheBox } from "react-awesome-reveal";
import SectionTitle from "../SectionTitle";
import sImg1 from "../../images/story/4.jpg";
import sImg2 from "../../images/story/5.jpg";
import sImg3 from "../../images/story/6.jpg";
import rsvp from "../../images/laraibandnick/rsvp.png";
import attire from "../../images/laraibandnick/attire.png";
import henna from "../../images/laraibandnick/henna.png";
import hotel from "../../images/laraibandnick/hotel.png";
import location from "../../images/laraibandnick/location.png";

import flowerShape from "../../images/story/flower.svg";
import flowerShape2 from "../../images/story/flower-2.svg";
import pin from "../../images/story/pin.svg";
import ring from "../../images/story/ring.svg";

import shape1 from "../../images/story/flower-shape1.svg";
import shape2 from "../../images/story/flower-shape2.svg";
import shape3 from "../../images/story/flower-shape3.svg";
import shape4 from "../../images/story/flower-shape4.svg";

const Storys = [
  {
    sImg: rsvp,
    flower: flowerShape,
    title: "When should I RSVP by?",
    date: "Dec 15, 2023",
    showThumb: true,
    description: "Please RSVP by Friday, December 15th. If you have any questions, reach out to Laraib or Nick.",
  },
  {
    sImg: attire,
    flower: flowerShape2,
    title: "What is the attire?",
    showThumb: false,
    description:
      "For the Mehndi event on Saturday, January 13th, guests are encouraged to wear colorful formal Pakistani or Indian attire or opt for Western wear. For the wedding ceremony and reception, guests have the option of wearing black-tie attire or Pakistani/Indian formal wear.",
    order1: "order-lg-2 order-1",
    order2: "order-lg-1 order-2",
  },
  {
    sImg: henna,
    flower: flowerShape,
    title: "What is Mehndi?",
    showThumb: false,
    description:
      "Mehndi is a pre-wedding celebration, considered an auspicious moment for the soon-to-be-married couple. This vibrant event symbolizes joy, prosperity, love, and the strength of the marital bond. The evening is infused with color, lively music, and delectable treats. Guests will have the opportunity to adorn their hands with henna designs, adding a personal touch to this festive occasion. Fun for the whole family!",
  },
  {
    sImg: hotel,
    title: "Are there any hotel accommodations?",
    date: "Dec 8, 2023",
    showThumb: false,
    description:
      "We've secured special rates at two hotels for your convenience. The first is La Quinta Inn and Suites in Coral Springs, where our Mehndi event will take place on Saturday, January 13th. The second hotel, Renaissance Fort Lauderdale West, is located just under 10 minutes away from the wedding venue on Sunday, January 14th. Please refer to the hotel page for more details and use the provided links to take advantage of these special rates. Due to a limited number of rooms, we recommend booking as soon as possible. The room block will be available until January 5th or until all allocated rooms are filled.",
    order1: "order-lg-2 order-1",
    order2: "order-lg-1 order-2",
  },
  {
    sImg: location,
    flower: flowerShape,
    title: "How far are the venues from each other, and is there a parking fee?",
    showThumb: false,
    description:
      "La Quinta Banquet Hall and Jacaranda Country Club are about 25 minutes from each other. La Quinta offers free self-parking on-site, while Jacaranda offers complimentary valet parking or self-parking.",
  },
];

const StorySection = (props) => {
  return (
    <section className="wpo-story-section-s2 section-padding" id="story">
      <div className="container">
        {/* <SectionTitle /> */}
        <div className="wpo-story-wrap">
          {Storys.map((story, st) => (
            <div className="wpo-story-item" key={st}>
              <div className="row">
                <div className={`col col-lg-6 col-12 ${story.order1}`}>
                  <div className="wpo-story-img">
                    <img src={story.sImg} alt="" />
                  </div>
                </div>
                <div className={`col col-lg-6 col-12 ${story.order2}`}>
                  <JackInTheBox duration="1000" triggerOnce="true">
                    <div className="wpo-story-content">
                      {story.showThumb ? (
                        <div className="thumb">
                          <span>{story.date}</span>
                          <div className="pin">
                            <img src={pin} alt="" />
                          </div>
                        </div>
                      ) : null}
                      <h2>{story.title}</h2>
                      <p>{story.description}</p>
                      <div className="flower">
                        <img src={story.flower} alt="" />
                      </div>
                    </div>
                  </JackInTheBox>
                </div>
              </div>
              <div className="ring-wrap">
                {Array.apply(null, { length: 10 }).map((e, i) => (
                  <div className="ring" key={i}>
                    <img src={ring} alt="" />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="flower-shape-1">
        <img src={shape1} alt="" />
      </div>
      <div className="flower-shape-2">
        <img src={shape2} alt="" />
      </div>
      <div className="flower-shape-3">
        <img src={shape3} alt="" />
      </div>
      <div className="flower-shape-4">
        <img src={shape4} alt="" />
      </div>
    </section>
  );
};

export default StorySection;
