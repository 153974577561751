import React, { Fragment } from "react";
import HotelsSection from "../../components//HotelsSection/HotelsSection";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/footer/Footer";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";

const HotelsPage = () => {
  return (
    <Fragment>
      <Navbar hclass={"wpo-header-style-3"} />
      <HotelsSection />
      <Footer footerClass={"wpo-site-footer-s2"} />
      <Scrollbar />
    </Fragment>
  );
};

export default HotelsPage;
