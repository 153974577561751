import React, { Fragment } from "react";
import EventSectionS2 from "../../components/EventSection2/EventSection2";

const AccomodationPage = () => {
  return (
    <Fragment>
      {/* <Navbar hclass={"wpo-header-style-3"} /> */}
      {/* <PageTitle pageTitle={"Accomodation"} pagesub={"Accomodation"} /> */}
      {/* <WeddingDate2 pbClass={"pb-0"} /> */}
      {/* <CoupleSection3 cClass={"pt-160"} /> */}
      {/* <VideoSection vimg={vImg} /> */}
      {/* <EventSection /> */}
      <EventSectionS2 />
      {/* <PartnerSection pClass={"section-padding pt-0"} /> */}
      {/* <Footer footerClass={"wpo-site-footer-s2"} /> */}
      {/* <Scrollbar /> */}
    </Fragment>
  );
};
export default AccomodationPage;
