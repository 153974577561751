import React from "react";
import { Slide } from "react-awesome-reveal";
import ceremony from "../../images/laraibandnick/ceremony.jpg";
import mehndi from "../../images/laraibandnick/mendhi.jpg";
import reception from "../../images/laraibandnick/reception.jpg";
import SectionTitle2 from "../SectionTitle2";
import LocationMap from "./Modal";

// const { title, description, location, startTime, endTime } = event;
const Events = [
  {
    Simg: mehndi,
    title: "The Mehndi",
    li1: "Saturday, January 13, 2024",
    li2: "Starting at 7pm",
    li3: "La Quinta Inn Banquet Hall",
    li4: "3701 N University Dr, Coral Springs, FL 33065",
    location:
      "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14310.271282861937!2d-80.2496437!3d26.2756864!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d905df185a0e7f%3A0x74b743232aee845a!2sLa%20Quinta%20Inn%20Banquet%20Hall!5e0!3m2!1sen!2sus!4v1700031080860!5m2!1sen!2sus",
    animation: "1200",
    event: {
      title: "The Mehndi",
      description:
        "Guests are encouraged to wear colorful formal Pakistani or Indian attire or opt for Western wear.",
      location: "3701 N University Dr, Coral Springs, FL 33065",
      startTime: new Date("January 13, 2024 19:00:00"),
      endTime: new Date("January 13, 2024 24:00:00"),
      download: "laraibandnick-mehndi.ics",
    },
  },
  {
    Simg: ceremony,
    title: "The Ceremonies",
    li1: "Sunday, January 14, 2024",
    li2: "Starting at 4:30pm",
    li3: "Jacaranda Country Club",
    li4: "9200 W Broward Blvd, Plantation, FL 33324",
    location:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.611349989007!2d-80.27752201189087!3d26.111602618174874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d907de9f73e7ef%3A0x1dbacb193f994b17!2sJacaranda%20Golf%20Club!5e0!3m2!1sen!2sus!4v1700017104764!5m2!1sen!2sus",
    animation: "1400",
    event: {
      title: "The Ceremonies",
      description:
        "Guests have the option of wearing black-tie attire or Pakistani/Indian formal wear.",
      location: "9200 W Broward Blvd, Plantation, FL 33324",
      startTime: new Date("January 14, 2024 16:30:00"),
      endTime: new Date("January 14, 2024 18:00:00"),
      download: "laraibandnick-ceremonies.ics",
    },
  },
  {
    Simg: reception,
    title: "The Reception",
    li1: "Sunday, January 14, 2024",
    li2: "Starting at 7:00pm",
    li3: "Jacaranda Country Club",
    li4: "9200 W Broward Blvd, Plantation, FL 33324",
    location:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3582.611349989007!2d-80.27752201189087!3d26.111602618174874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88d907de9f73e7ef%3A0x1dbacb193f994b17!2sJacaranda%20Golf%20Club!5e0!3m2!1sen!2sus!4v1700017104764!5m2!1sen!2sus",
    animation: "1600",
    event: {
      title: "The Reception",
      description:
        "Guests have the option of wearing black-tie attire or Pakistani/Indian formal wear.",
      location: "9200 W Broward Blvd, Plantation, FL 33324",
      startTime: new Date("January 14, 2024 19:00:00"),
      endTime: new Date("January 14, 2024 24:00:00"),
      download: "laraibandnick-reception.ics",
    },
  },
];

const EventSection = (props) => {
  return (
    <section className="wpo-event-section section-padding" id="event">
      <div className="container">
        <SectionTitle2 subTitle={"Our Wedding"} MainTitle={"When & Where"} />
        <div className="wpo-event-wrap">
          <div className="row">
            {Events.map((event, eitem) => (
              <div className="col col-lg-4 col-md-6 col-12" key={eitem}>
                <Slide
                  direction="up"
                  duration={event.animation}
                  triggerOnce="true"
                >
                  <div className="wpo-event-item">
                    <div className="wpo-event-img">
                      <div className="wpo-event-img-inner">
                        <img src={event.Simg} alt="" />
                      </div>
                    </div>
                    <div className="wpo-event-text">
                      <div className="title">
                        <h2>{event.title}</h2>
                      </div>
                      <ul>
                        <li>{event.li1}</li>
                        <li>{event.li2}</li>
                        <li>{event.li3}</li>
                        <li>{event.li4}</li>
                        <li>
                          <LocationMap location={event.location} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </Slide>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventSection;
