import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import CoupleSection2 from "../../components/CoupleSection2/CoupleSection2";
import EventSection from "../../components/EventSection/EventSection";
import Navbar from "../../components/Navbar/Navbar";
import PortfolioSection from "../../components/PortfolioSection";
import StorySection2 from "../../components/StorySection2/StorySection2";
import Footer from "../../components/footer/Footer";
import Hero2 from "../../components/hero2/hero2";
import Scrollbar from "../../components/scrollbar/scrollbar";

const HomePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Laraib and Nick's Wedding | January 13 and 14, 2024</title>
        <meta
          name="description"
          content="Laraib and Nick's Wedding will be held January 13 in Coral Springs and 14 in Plantation."
        />
      </Helmet>
      <Navbar />
      <Hero2 />
      <CoupleSection2 />
      <StorySection2 />
      <PortfolioSection />
      {/* <RsvpSection/> */}
      <EventSection />
      {/* <BlogSection /> */}
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default HomePage;
