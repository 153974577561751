import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import Lightbox from "yet-another-react-lightbox";
import "yet-another-react-lightbox/styles.css";
import casino from "../../images/laraibandnick/casino.png";
import dinner from "../../images/laraibandnick/dinner.jpg";
import heat from "../../images/laraibandnick/heat.png";
import snow from "../../images/laraibandnick/snow.png";
import wedding from "../../images/laraibandnick/wedding.png";
import wedding1 from "../../images/laraibandnick/wedding1.png";

const settings = {
  dots: false,
  arrows: true,
  speed: 1000,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  responsive: [
    {
      breakpoint: 1500,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 991,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const Portfolios = [
  {
    Pimg: wedding,
  },
  {
    Pimg: snow,
  },
  {
    Pimg: wedding1,
  },
  {
    Pimg: dinner,
  },
  {
    Pimg: casino,
  },
  {
    Pimg: heat,
  },
];

const Slides = [
  { src: wedding },
  { src: snow },
  ,
  { src: wedding1 },
  { src: dinner },
  { src: casino },
  { src: heat },
];
let Slide = [];
const PortfolioSection = (props) => {
  const [open, setOpen] = React.useState(false);

  const reorderSlides = (image) => {
    Slide = [
      { src: image.Pimg },
      ...Slides.filter((img) => img.src !== image.Pimg),
    ];
    setOpen(true);
  };

  return (
    <section
      className={`wpo-portfolio-section section-padding ${props.ptClass}`}
      id="gallery"
    >
      <h2 className="hidden">some</h2>
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-12">
            <div className="portfolio-grids gallery-container clearfix portfolio-slide">
              <Slider {...settings}>
                {Portfolios.map((image, i) => (
                  <div className="grid" key={i}>
                    <div
                      className="img-holder"
                      onClick={() => reorderSlides(image)}
                    >
                      <img
                        src={image.Pimg}
                        alt=""
                        className="img img-responsive"
                      />
                      <div className="hover-content">
                        <i className="ti-plus"></i>
                      </div>
                    </div>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <Lightbox open={open} close={() => setOpen(false)} slides={Slide} />
    </section>
  );
};

export default PortfolioSection;
