import React from "react";

const SectionTitle2 = (props) => {
  return (
    <div className={`wpo-section-title ${props.s2Class}`}>
      <span>{props.subTitle}</span>
      <h2>{props.MainTitle}</h2>
    </div>
  );
};

export default SectionTitle2;
