import React from "react";
import AllRoute from "../router";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../sass/style.scss";
import { Analytics } from "@vercel/analytics/react";

const App = () => {
  return (
    <div className="App" id="scroll">
      <AllRoute />
      <ToastContainer />
      <Analytics />
    </div>
  );
};

export default App;
